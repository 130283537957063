@use '../util/' as u;

.homeitem {
  &__contentContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-block-start: 30px;
  }

  &__light {
    background-color: var(--light-bg);
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .text {
      grid-column: 1/2;
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-inline: u.rem(20);
      padding-block: u.rem(20);

      p {
        color: var(--content-text-color-dark);
        text-align: center;
      }
    }

    .image {
      grid-column: 1/2;
      grid-row: 1/2;
      height: u.rem(150);
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include u.breakpoint('medium') {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;

      .text {
        grid-column: 2/3;
        grid-row: 1/2;
        padding-inline: u.rem(40);
        padding-block: u.rem(10);
      }

      .image {
        grid-column: 1/2;
        grid-row: 1/2;
        height: u.rem(200);
      }
    }

    .image:hover {
      cursor: pointer;

      .homeitemButton {
        background-color: var(--secondary-button-bg-hover);
        border: 2px solid var(--secondary-button-border-hover);
        color: var(--secondary-button-text-color-hover);
      }
    }
  }

  &__dark {
    background-color: var(--dark-bg);
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .text {
      grid-column: 1/2;
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-inline: u.rem(20);
      padding-block: u.rem(20);

      p {
        color: var(--content-text-color-light);
        text-align: center;
      }
    }

    .image {
      grid-column: 1/2;
      grid-row: 1/2;
      height: u.rem(130);
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-size 0.3s ease-in-out;
    }

    @include u.breakpoint('medium') {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;

      .text {
        grid-column: 1/2;
        grid-row: 1/2;
        padding-inline: u.rem(40);
        padding-block: u.rem(15);
      }

      .image {
        grid-column: 2/3;
        grid-row: 1/2;
        height: u.rem(200);
      }
    }

    .image:hover {
      cursor: pointer;

      .homeitemButton {
        background-color: var(--secondary-button-bg-hover);
        border: 2px solid var(--secondary-button-border-hover);
        color: var(--secondary-button-text-color-hover);
      }
    }
  }
}
