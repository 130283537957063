@use '../util/' as u;

.footer {
  $b: &;

  background-color: var(--dark-bg);
  margin-block-start: u.rem(60);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-inline: u.rem(20);
  padding-block-start: u.rem(10);
  color: var(--content-text-color-light);
  position: bottom;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: u.rem(20) u.rem(10);

  @include u.breakpoint('medium') {
    grid-template-columns: repeat(4, auto);
    gap: u.rem(0) u.rem(50);
  }

  li {
    font-size: u.rem(14);
    font-size: clamp(u.rem(14), 0.83rem + 0.19vw, u.rem(16));
    padding-bottom: 5px;
  }

  .li:hover {
    color: var(--text-color-light);
  }

  &__socialicon {
    height: u.rem(14);
    height: clamp(u.rem(14), 0.83rem + 0.19vw, u.rem(16));
    width: auto;
    display: inline;
    margin-right: 5px;
    fill: var(--content-text-color-light);
  }

  &__externicon {
    height: u.rem(12);
    height: clamp(u.rem(12), 0.71rem + 0.19vw, u.rem(14));
    width: auto;
    display: inline;
    margin-left: 3px;
  }

  &__link {
    color: var(--content-text-color-light);
    display: inline-flex;
    align-items: center;

    &:hover {
      color: var(--text-color-light);

      #{$b}__socialicon,
      #{$b}__link * #{$b}__socialicon *#{$b}__logo * {
        fill: var(--text-color-light);
      }
    }
  }

  &__collumn1 {
    grid-column: 1/2;
    grid-row: 1/4;
    p {
      font-size: u.rem(10);
      white-space: nowrap;
    }

    @include u.breakpoint(medium) {
    }
  }

  &__logo {
    width: u.rem(70);
    height: auto;
    display: block;
    fill: var(--light-bg);

    &:hover {
      fill: var(--text-color-light);
    }
  }

  &__collumn2 {
    grid-column: 2/3;
    grid-row: 1/4;
    display: flex;
    align-items: flex-start;

    @include u.breakpoint(medium) {
    }
  }

  &__collumn3 {
    grid-column: 3/5;
    grid-row: 1/3;
    display: flex;
    align-items: flex-start;

    @include u.breakpoint('medium') {
      grid-column: 3/4;
    }
  }

  &__collumn4 {
    grid-column: 3/5;
    grid-row: 2/3;

    @include u.breakpoint('medium') {
      grid-column: 4/5;
      grid-row: 1/2;
    }
  }

  &__privacy {
    @include u.breakpoint-down('small') {
      font-size: u.rem(10);
      white-space: nowrap;
    }
  }
}
