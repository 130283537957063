@use '../util' as u;

.button {
  display: inline-block;
  font-size: u.rem(15);
  line-height: 0.7;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-align: center;
  padding: u.rem(14); // top+bottom 14px
  border-radius: u.rem(24);
  height: u.rem(40);
  width: u.rem(150);

  @include u.breakpoint('medium') {
    height: u.rem(45);
    width: u.rem(165);
    font-size: u.rem(20);
    line-height: 0.75;
  }

  transition-property: background-color, border, color; //smooth transition on hover
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &.primary {
    background-color: var(--primary-button-bg);
    color: var(--text-color-light);

    &:hover {
      background-color: var(--primary-button-bg-hover);
    }
  }

  &.secondary {
    padding: u.rem(12);
    background-color: hsla(49, 11%, 30%, 0.2);
    backdrop-filter: blur(5px);
    border: 2px solid var(--secondary-button-border);
    color: var(--secondary-button-text-color);

    &:hover {
      background-color: var(--secondary-button-bg-hover);
      border: 2px solid var(--secondary-button-border-hover);
      color: var(--secondary-button-text-color-hover);
    }
  }
}

.homeitemButton {
  font-weight: 700;
  line-height: 1;
  color: var(--content-text-color-light);
  border: 2px solid var(--content-text-color-light);
  border-radius: u.rem(30);
  padding-block: u.rem(10);
  padding-inline: u.rem(30);
  background-color: hsla(49, 11%, 30%, 0.2);
  backdrop-filter: blur(3px);
  transition-property: background-color, border, color; //smooth transition on hover
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.filterbutton {
  font-family: u.$font;
  font-size: u.rem(14);
  font-size: clamp(u.rem(14), 0.84rem + 0.19vw, u.rem(16));
  font-weight: 700;
  text-transform: uppercase;

  color: var(--content-text-color-light);
  border-radius: u.rem(30);
  padding-block: u.rem(8);
  padding-inline: u.rem(20);
  transition-property: background-color, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  opacity: 0.6;

  &.All {
    background-color: var(--all-color);
    border: 2px solid var(--all-color);
  }

  &.React {
    background-color: var(--react-color);
    border: 2px solid var(--react-color);
  }

  &.Mongo-DB {
    background-color: var(--mongo-db-color);
    border: 2px solid var(--mongo-db-color);
  }

  &.Express {
    background-color: var(--express-color);
    border: 2px solid var(--express-color);
  }

  &.Node {
    background-color: var(--node-color);
    border: 2px solid var(--node-color);
  }

  &.SASS {
    background-color: var(--sass-color);
    border: 2px solid var(--sass-color);
  }

  &.JavaScript {
    background-color: var(--javascript-color);
    border: 2px solid var(--javascript-color);
  }

  &.CSS {
    background-color: var(--css-color);
    border: 2px solid var(--css-color);
  }

  &.Website {
    background-color: var(--website-color);
    border: 2px solid var(--website-color);
  }

  &.Web-App {
    background-color: var(--web-app-color);
    border: 2px solid var(--web-app-color);
  }

  &.API {
    background-color: var(--api-color);
    border: 2px solid var(--api-color);
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    border: 2px solid var(--dark-bg);
    color: var(--text-color-light);
  }
}
