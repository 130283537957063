@use '../util/' as u;

.projects {
  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding-block-start: 45px;
    row-gap: 80px;
  }

  &__item {
    background-color: var(--dark-light);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    .text {
      grid-column: 1/2;
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: var(--content-text-color-dark);
      text-align: left;

      h1 {
        padding-block: u.rem(10);
      }
    }

    .image {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
    }

    .buttons {
      grid-column: 1/2;
      grid-row: 3/4;
      padding-block: u.rem(15);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
      height: auto;

      .svgbuttons {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .icon {
          fill: var(--content-text-color-dark);
          transition-property: fill;
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;

          &:hover {
            fill: var(--primary-button-bg-hover);
          }
        }

        .website {
          height: 40px;
          width: 45px;
        }

        .github {
          height: 41.08px;
          width: 40px;
        }
      }

      .morebutton {
        display: inline-block;
        font-size: u.rem(15);
        line-height: 0.7;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-align: center;
        padding: u.rem(14); // top+bottom 14px
        border-radius: u.rem(24);
        height: u.rem(40);
        width: u.rem(150);

        @include u.breakpoint('medium') {
          height: u.rem(45);
          width: u.rem(165);
          font-size: u.rem(20);
          line-height: 0.75;
        }

        transition-property: background-color, border, color; //smooth transition on hover
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;

        padding: u.rem(12);
        border: 2px solid var(--text-color-dark);
        color: var(--text-color-dark);

        &:hover {
          background-color: var(--dark-bg);
          border: 2px solid var(--dark-bg);
          color: var(--text-color-light);
          cursor: pointer;
        }
      }
    }

    .tags {
      grid-column: 1/2;
      grid-row: 4/5;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 5px;

      .tagbox {
        width: auto;
        padding-inline: 8px;
        padding-block: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: white;
          text-transform: uppercase;
          font-weight: 700;
          line-height: 1;
        }
      }

      .All {
        display: none;
      }

      .React {
        background-color: var(--react-color);
      }

      .Mongo-DB {
        background-color: var(--mongo-db-color);
      }

      .Express {
        background-color: var(--express-color);
      }

      .Node {
        background-color: var(--node-color);
      }

      .SASS {
        background-color: var(--sass-color);
      }

      .CSS {
        background-color: var(--css-color);
      }

      .JavaScript {
        background-color: var(--javascript-color);
      }

      .Website {
        background-color: var(--website-color);
      }

      .Web-App {
        background-color: var(--web-app-color);
      }

      .API {
        background-color: var(--api-color);
      }
    }

    @include u.breakpoint('medium') {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;

      .text {
        grid-column: 2/3;
        grid-row: 1/2;
        margin-left: u.rem(15);

        h1 {
          padding-block-start: u.rem(0);
        }
      }

      .image {
        grid-column: 1/2;
        grid-row: 1/2;
        padding-right: 20px;
      }

      .buttons {
        grid-column: 2/3;
        grid-row: 3/4;
      }

      .tags {
        grid-column: 1/3;
        grid-row: 4/5;
      }
    }

    &.wide {
      grid-template-columns: 5fr 1fr;
      grid-template-rows: auto auto auto;

      .text {
        grid-column: 1/2;
        grid-row: 2/3;
        margin-inline-start: 0;
        margin-inline-end: u.rem(20);
        margin-block-end: u.rem(15);

        h1 {
          padding-block-start: u.rem(15);
        }
      }

      .antiSwipe {
        grid-column: 1/3;
        grid-row: 1/2;
      }

      .buttons {
        grid-column: 2/3;
        grid-row: 2/3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
      }

      .tags {
        grid-column: 1/3;
        grid-row: 4/5;
      }
    }
  }
}

.antiSwipe {
  &__div {
    display: flex;

    h3 {
      color: white;
    }

    @include u.breakpoint('medium') {
      display: none;
    }

    &__icon {
      width: 50px;
      height: 50px;
      background-color: var(--dark-color-mobile-gallery-opacity);
      border-radius: 50%;
      padding: 5px;
    }
  }

  .toggleWideButton {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    width: u.rem(20);
    width: clamp(
      u.rem(20),
      0.919894366197183rem + 1.4084507042253522vw,
      u.rem(35)
    );
    height: u.rem(20);
    height: clamp(
      u.rem(20),
      0.919894366197183rem + 1.4084507042253522vw,
      u.rem(35)
    );
    background-color: var(--dark-color-mobile-gallery-opacity);
    border-radius: 50%;
    padding: 5px;
    border: none;
    opacity: 50%;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 100%;
      transform: scale(1.2);

      .toggleIcon {
        color: var(--secondary-button-bg-hover);
      }
    }

    .toggleIcon {
      color: white;
      height: 100%;
      width: 100%;
    }

    @include u.breakpoint-down('small') {
      display: none;
    }
  }
}
